var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticStyle:{"min-height":"400px"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.hasError)?_c('div',{staticClass:"alert-body mb-2 alert alert-dismissible alert-danger p-1"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('errors.color-error'))+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center mb-1"},[_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"18"}}),_c('h4',{staticClass:"mb-0 ml-75"},[_vm._v(" Ημερολόγιο ")])],1),_c('p',{staticClass:"lead mb-2"},[_vm._v(_vm._s(_vm.$t('settings.calendar-msg')))])])],1),_c('div',[_c('div',[_c('validation-observer',{ref:"observer",attrs:{"rules":{ required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",staticClass:"repeater-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},_vm._l((_vm.calendars.calendars),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"cols":"6","md":"4"}},[_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{class:_vm.getValidationClass(validationContext),attrs:{"label":"Ημερολόγιο","label-for":"item-name"}},[_c('b-form-input',{attrs:{"id":"item-name","state":_vm.getValidationState(validationContext),"placeholder":"Εργασία, Προσωπικό κτλ","type":"text"},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"6","md":"4"}},[_c('v-select',{staticClass:"per-page-selector d-inline-block w-100",attrs:{"clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.getColors,"reduce":function (val) { return val.value; },"searchable":false,"label":"text"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var value = ref.value;
var label = ref.label;
var active = ref.active;
var preview = ref.preview;
return [(active)?_c('div',{staticClass:"rounded-circle d-inline-block mr-50",staticStyle:{"height":"10px","width":"10px"},style:({backgroundColor:value})}):_vm._e(),(active)?_c('span',[_vm._v(" "+_vm._s(preview))]):_vm._e()]}},{key:"selected-option",fn:function(ref){
var value = ref.value;
var label = ref.label;
var active = ref.active;
var preview = ref.preview;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",staticStyle:{"height":"10px","width":"10px"},style:({backgroundColor:value})}),_c('span',[_vm._v(" "+_vm._s(preview))])]}},{key:"no-options",fn:function(){return [_vm._v(_vm._s(_vm.$t('errors.no-matching')))]},proxy:true}],null,true),model:{value:(item.color),callback:function ($$v) {_vm.$set(item, "color", $$v)},expression:"item.color"}})],1),_c('b-col',{staticClass:"mb-50 mt-1 mt-md-0",attrs:{"col":"","md":"3"}},[_c('b-button',{staticClass:"mt-0 mt-md-2 btn-icon rounded-circle",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.$emit('removeItem',index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1),(_vm.getGeneralCalendar.max > _vm.calendars.calendars.length && index === _vm.calendars.calendars.length - 1)?_c('b-button',{staticClass:"mt-0 ml-1 mt-md-2 btn-icon rounded-circle",attrs:{"variant":"success"},on:{"click":function($event){return _vm.$emit('repeatAgain')}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1),_c('b-button',{attrs:{"disabled":invalid,"variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('generic.save'))+" ")])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }