<template>
   <b-card>
	  <b-row>
		 <b-col cols="12">
			<div class="d-flex align-items-center mb-2">
			   <feather-icon icon="MailIcon"
							 size="18" />
			   <h4 class="mb-0 ml-75">
				  {{ $t('settings.mail-title') }}
			   </h4>
			</div>
			<p class="lead mb-0">{{ $t('settings.mail-msg1') }}</p>
			<p class="lead mb-2">{{ $t('settings.mail-msg2') }} <strong class="text-danger">%s</strong>
								 {{ $t('settings.mail-msg3') }}</p>
			<quill-editor
				id="quil-content"
				v-model="form.textarea"
				:options="editorOption"
				class="border-bottom-0"
			/>
		 </b-col>
	  </b-row>

	  <!-- form -->
	  <b-form>
		 <b-row>

			<b-col cols="12">
			   <b-button class="mt-2 mr-1"
						 variant="primary"
						 @click="saveMail">
				  {{ $t('generic.save') }}
			   </b-button>
			   <b-button class="mt-2"
						 variant="outline-secondary"
						 @click="resetMail">
				  {{ $t('generic.reset') }}
			   </b-button>
			</b-col>

		 </b-row>
	  </b-form>

   </b-card>
</template>

<script>
import {BAlert, BButton, BCard, BCardText, BCol, BForm, BRow} from 'bootstrap-vue'
import {quillEditor} from 'vue-quill-editor'
import toastMixin from "@/Mixins/ToastMixin";


export default {
   components: {
	  BButton,
	  BRow,
	  BForm,
	  BCol,
	  BAlert,
	  BCard,
	  BCardText,
	  quillEditor
   },
   mixins: [toastMixin],
   data() {
	  return {
		 form: {
			textarea: null
		 },
		 editorOption: {
			modules: {
			   toolbar: '#quill-toolbar',
			},
			placeholder: 'Message',
		 }
	  }
   },
   methods: {
	  saveMail() {
		 this.$store.dispatch('saveMail', this.form.textarea);

		 this.notify(`Αποθηκεύτηκε`);
	  },
	  resetMail() {
		 this.$store.dispatch('resetMail');
		 this.form.textarea = this.$store.getters.getMail.content;
		 this.notify(`Επαναφορά mail`);
	  }
   },
   async created() {
	  await this.$store.dispatch('fetchMail');
	  this.form.textarea = this.$store.getters.getMail.content;
   }
}


</script>

<style lang="scss">

@import "@core/scss/vue/libs/quill.scss";


.ql-editor {
   height: 150px !important;
}


.ql-container {
   height: auto !important;
}


#quil-content ::v-deep {
   > .ql-container {
	  border-bottom: 0;
   }

   + #quill-toolbar {
	  border-top-left-radius: 0;
	  border-top-right-radius: 0;
	  border-bottom-left-radius: $border-radius;
	  border-bottom-right-radius: $border-radius;
   }
}
</style>