<template>
   <!-- start::personal data -->
   <b-card>
	  <!-- media -->
	  <!--/ media -->
	  <validation-observer ref="observer"
						   v-slot="{handleSubmit , invalid}">
		 <!-- form -->
		 <b-form class="mt-2">
			<b-row>
			   <b-col sm="12">
				  <b-media class="mb-2">
					 <template #aside>
						<b-avatar
							ref="previewEl"
							:src="user.photo"
							:text="fullName(user.surname,  user.name)"
							rounded
							size="90px"
						/>
					 </template>
					 <h4 class="mb-1">
						{{ user.surname }} {{ user.name }}
					 </h4>
					 <div class="d-flex flex-wrap">
						<label class="d-none mr-1 d-sm-inline cursor-pointer btn btn-outline-secondary"
							   for="file">
						   <input
							   id="file"
							   ref="refInputEl"
							   accept=".jpg,.jpeg,.png,"
							   class="d-none"
							   type="file"
							   @change="onPreview($event,user),dirty = true"
						   >
						   {{ $t('contacts.change') }}
						   <feather-icon
							   class="d-inline d-sm-none"
							   icon="EditIcon"
						   />
						</label>
						<label class="d-none d-sm-inline cursor-pointer btn btn-outline-secondary"

							   @click="removeImage(user)">
						   {{ $t('contacts.remove') }}
						   <feather-icon
							   class="d-inline d-sm-none"
							   icon="TrashIcon"
						   />
						</label>
					 </div>
				  </b-media>

			   </b-col>

			   <b-col sm="6">
				  <!--username-->
				  <validation-provider v-slot="validationContext"
									   :rules="{ required: true}">
					 <b-form-group
						 label="Username"
						 label-for="name">
						<b-input-group :class="getValidationClass(validationContext)"
									   class="input-group-merge">
						   <b-input-group-prepend is-text>
							  <feather-icon icon="UserIcon" />
						   </b-input-group-prepend>
						   <b-form-input id="username"
										 v-model="user.username"
										 :state="getValidationState(validationContext)"
										 placeholder="john"
										 @keydown="nameKeydown($event)" />
						</b-input-group>
						<b-form-invalid-feedback id="form-name-lice-feedback">
						   {{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					 </b-form-group>

				  </validation-provider>
			   </b-col>

			   <b-col sm="6">
				  <!--email-->
				  <validation-provider v-slot="validationContext"
									   :rules="{required:true, email: true}">
					 <b-form-group

						 label="E-mail"
						 label-for="email"
					 >
						<b-input-group :class="getValidationClass(validationContext)"
									   class="input-group-merge">
						   <b-input-group-prepend is-text>
							  <feather-icon icon="MailIcon" />
						   </b-input-group-prepend>
						   <b-form-input id="email"
										 v-model="user.email"
										 :state="getValidationState(validationContext)"
										 placeholder="mail@site.com"
										 type="email" />
						</b-input-group>
						<b-form-invalid-feedback>
						   {{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="6">
				  <validation-provider v-slot="validationContext"
									   :rules="{ required: true}">
					 <b-form-group

						 :label="$t('contacts.surname')"
						 label-for="surname"
					 >
						<b-input-group :class="getValidationClass(validationContext)"
									   class="input-group-merge">
						   <b-input-group-prepend is-text>
							  <feather-icon icon="UserIcon" />
						   </b-input-group-prepend>
						   <b-form-input id="surname"
										 v-model="user.surname"
										 :state="getValidationState(validationContext)"
										 placeholder="Doe" />
						</b-input-group>
						<b-form-invalid-feedback id="form-surname-lice-feedback">
						   {{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="6">
				  <validation-provider v-slot="validationContext"
									   :rules="{ required: true}">
					 <b-form-group

						 :label="$t('contacts.name')"
						 label-for="name">
						<b-input-group :class="getValidationClass(validationContext)"
									   class="input-group-merge">
						   <b-input-group-prepend is-text>
							  <feather-icon icon="UserIcon" />
						   </b-input-group-prepend>
						   <b-form-input id="name"
										 v-model="user.name"
										 :state="getValidationState(validationContext)"
										 placeholder="John" />
						</b-input-group>
						<b-form-invalid-feedback id="form-name-lice-feedback">
						   {{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					 </b-form-group>

				  </validation-provider>
			   </b-col>

			   <b-col sm="6">
				  <validation-provider v-slot="validationContext"
									   :rules="{ numeric: true,max:10}"
									   name="telephone">
					 <b-form-group

						 :label="$t('contacts.phone')"
						 label-for="phone"
					 >
						<b-input-group :class="getValidationClass(validationContext)"
									   class="input-group-merge">
						   <b-input-group-prepend is-text>
							  <feather-icon icon="PhoneIcon" />
						   </b-input-group-prepend>
						   <b-form-input
							   id="phone"
							   ref="telephone"
							   v-model="user.telephone"
							   :state="getValidationState(validationContext)"
							   placeholder="2101234567"
							   @keyup="removeValidatorIcon('telephone')" />
						</b-input-group>
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="6">
				  <validation-provider v-slot="validationContext"
									   :rules="{ numeric: true , max:10}"
									   name="mobile">
					 <b-form-group

						 :label="$t('contacts.mobile')"
						 label-for="mobile"
					 >
						<b-input-group :class="getValidationClass(validationContext)"
									   class="input-group-merge">
						   <b-input-group-prepend is-text>
							  <feather-icon icon="SmartphoneIcon" />
						   </b-input-group-prepend>
						   <b-form-input id="mobile"
										 ref="mobile"
										 v-model="user.mobile"
										 :state="getValidationState(validationContext)"
										 placeholder="6988888888"
										 @keyup="removeValidatorIcon('mobile')" />
						</b-input-group>
					 </b-form-group>
					 <b-form-invalid-feedback v-if="validationContext.errors[0]">
						{{ $t(getError(validationContext.errors[0])) }}
					 </b-form-invalid-feedback>
				  </validation-provider>
			   </b-col>

			   <b-col cols="12 mt-2">
				  <div class="d-flex align-items-center mb-1">
					 <feather-icon icon="LockIcon"
								   size="18" />
					 <h4 class="mb-0 ml-75">Κωδικός πρόσβασης</h4>
				  </div>
				  <p>Πρέπει να γράψετε το ισχύων συνθηματικό σας για να τον αλλάξετε</p>
			   </b-col>

			   <b-col sm="4">
				  <validation-provider
					  v-slot="validationContext"
					  :rules="{ min:6}"
					  name="current_password">
					 <b-form-group label="Ισχύων συνθηματικό"
								   label-for="user-current-password">
						<b-input-group :class="getValidationClass(validationContext)"
									   class="input-group-merge">
						   <b-form-input id="user-current-password"
										 ref="current_password"
										 v-model="user.current_password"
										 :state="getValidationState(validationContext)"
										 class="form-control-merge"
										 name="user-current-password"
										 @blur="removeValidatorIcon('current_password')"
										 @keyup="removeValidatorIcon('current_password')" />
						   <b-input-group-append is-text>
							  <feather-icon :icon="passwordToggleIconOld"
											class="cursor-pointer" />
						   </b-input-group-append>
						</b-input-group>
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="4">
				  <validation-provider
					  v-slot="validationContext"
					  :rules="{ min:6}">
					 <b-form-group label="Νέο συνθηματικό"
								   label-for="user-new-password">
						<b-input-group :class="getValidationClass(validationContext)"
									   class="input-group-merge">
						   <b-form-input id="user-new-password"
										 ref="password1"
										 v-model="user.password1"
										 :state="getValidationState(validationContext)"
										 class="form-control-merge"
										 name="user-new-password"
										 type="password"
										 @blur="removeValidatorIcon('password1')"
										 @keyup="removeValidatorIcon('password1')" />
						   <b-input-group-append is-text>
							  <feather-icon :icon="passwordToggleIconNew"
											class="cursor-pointer"
											@click="togglePasswordNew" />
						   </b-input-group-append>
						</b-input-group>
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>


			   <b-col sm="4">
				  <validation-provider
					  v-slot="validationContext"
					  :rules="{ min:6}"
					  name="password2">
					 <b-form-group label="Επιβεβαίωση συνθηματικού"
								   label-for="user-confirm-password">
						<b-input-group :class="getValidationClass(validationContext)"
									   class="input-group-merge">
						   <b-form-input id="user-confirm-password"
										 ref="password2"
										 v-model="user.password2"
										 :state="getValidationState(validationContext)"
										 class="form-control-merge"
										 name="user-confirm-password"
										 type="password"
										 @blur="removeValidatorIcon('password2')"
										 @keyup="removeValidatorIcon('password2')" />
						   <b-input-group-append is-text>
							  <feather-icon :icon="passwordToggleIconNew"
											class="cursor-pointer"
											@click="togglePasswordRetype" />
						   </b-input-group-append>
						</b-input-group>
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col cols="12 mt-2">
				  <div class="d-flex align-items-center mb-1">
					 <feather-icon icon="LockIcon"
								   size="18" />
					 <h4 class="mb-0 ml-75">Socials</h4>
				  </div>
			   </b-col>

			   <!-- facebook -->
			   <b-col md="6">
				  <b-form-group label="Facebook"
								label-for="account-facebook">
					 <b-form-input id="account-facebook"
								   v-model="user.facebook"
								   placeholder="Όνομα χρήστη" />
				  </b-form-group>
			   </b-col>
			   <!--/ facebook -->

			   <!-- instagram -->
			   <b-col md="6">
				  <b-form-group label="Instagram"
								label-for="account-instagram">
					 <b-form-input id="account-instagram"
								   v-model="user.instagram"
								   placeholder="Όνομα χρήστη" />
				  </b-form-group>
			   </b-col>
			   <!--/ instagram -->

			   <!-- twitter -->
			   <b-col md="6">
				  <b-form-group
					  label="Twitter"
					  label-for="account-twitter">
					 <b-form-input id="account-twitter"
								   v-model="user.twitter"
								   placeholder="Όνομα χρήστη" />
				  </b-form-group>
			   </b-col>
			   <!--/ twitter -->

			   <!-- linkedin -->
			   <b-col md="6">
				  <b-form-group
					  label="LinkedIn"
					  label-for="account-linkedin">
					 <b-form-input id="account-linkedin"
								   v-model="user.linkedin"
								   placeholder="Όνομα χρήστη" />
				  </b-form-group>
			   </b-col>
			   <!-- linkedin -->

			   <b-col cols="12">
				  <b-button :disabled="invalid"
							class="mt-2 mr-1"
							variant="primary"
							@click="onUpdate">
					 Αποθήκευση
				  </b-button>
				  <b-button class="mt-2"
							type="reset"
							variant="outline-secondary">
					 Επαναφορά
				  </b-button>
			   </b-col>
			</b-row>
		 </b-form>
	  </validation-observer>
   </b-card>
   <!-- end::personal data-->
</template>

<script>


import {
   BAlert,
   BAvatar,
   BButton,
   BCard,
   BCardText,
   BCol,
   BForm,
   BFormCheckbox,
   BFormCheckboxGroup,
   BFormFile,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BFormRadio,
   BFormRadioGroup,
   BImg,
   BInputGroup,
   BInputGroupAppend,
   BInputGroupPrepend,
   BMedia,
   BMediaAside,
   BMediaBody,
   BRow,
   BTab,
   BTabs,
} from 'bootstrap-vue'
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {email, max, min, numeric, required} from "vee-validate/dist/rules";
import ToastMixin from "@/Mixins/ToastMixin";
import {heightTransition} from "@core/mixins/ui/transition";
import ImageMixin from "@/Mixins/ImageMixin";
import FormMixin from "@/Mixins/FormMixin";
import {avatarText} from "@core/utils/filter";
import caldoSettings from "@/caldoSettings";

extend('required', {
   ...required,
   message: 'Υποχρεωτικό πεδίο'

})
extend('numeric', {
   ...numeric,
   message: 'the-mobile-must-be-a-number'

})

extend("max", {
   ...max,
   message: 'max-ten-number'
});

extend("min", {
   ...min,
   message: 'min-6-number'
});


extend('email', {
   ...email,
   message: 'Μόρφη Email'
})


export default {
   components: {
	  BButton,
	  BForm,
	  BImg,
	  BFormFile,
	  BFormGroup,
	  BFormInput,
	  BInputGroup,
	  BRow,
	  BCol,
	  BAlert,
	  BCard,
	  BMedia,
	  BMediaAside,
	  BMediaBody,
	  BInputGroupAppend,
	  BTabs,
	  BTab,
	  BAvatar,
	  BInputGroupPrepend,
	  BFormRadio,
	  BFormRadioGroup,
	  BFormCheckboxGroup,
	  BFormCheckbox,
	  ValidationObserver,
	  ValidationProvider,
	  BCardText, BFormInvalidFeedback
   },
   data() {
	  return {
		 user: {
			name: null,
			username: null,
			surname: null,
			email: null,
			mobile: null,
			telephone: null,
			facebook: null,
			instagram: null,
			twitter: null,
			linkedin: null,
			photoType: null,
			photo: null,
			current_password: null,
			password1: null,
			password2: null
		 },
		 photo: null,
		 dirty: false
	  }
   },
   watch: {
	  'user.email': {
		 deep: true,
		 handler(email) {

			this.user.username = email.split('@').shift().replace(/[^a-zA-Z0-9]/g, '');
		 }
	  },
	  'user.username': {
		 deep: true,
		 handler(username) {

			console.log()
			this.user.username = username.replace(/[^a-zA-Z0-9]/g, '');
		 }
	  },
   },
   mixins: [ToastMixin, heightTransition, ImageMixin, FormMixin],


   methods: {
	  nameKeydown(e) {
		 if (/^\W$/.test(e.key)) {
			e.preventDefault();
		 }
	  },
	  fullName(name, surname) {
		 return avatarText(name, surname);
	  },
	  getColor(color) {
		 return caldoSettings.getColor(color);
	  },
	  togglePasswordOld() {
		 this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
	  },
	  togglePasswordNew() {
		 this.$refs.password1.type = this.$refs.password1.type === 'password' ? 'text' : 'password'
	  },
	  togglePasswordRetype() {
		 this.$refs.password2.type = this.$refs.password2.type === 'password' ? 'text' : 'password'
	  },
	  getRandomColor() {
		 var letters = '0123456789ABCDEF';
		 var color = '#';
		 for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		 }
		 return color;
	  },

	  getValidationState({dirty, validated, valid = null}) {
		 this.getValidationClass(validated)
		 return validated ? valid : null;
	  },

	  getValidationClass(validated) {
		 if ((validated.valid !== undefined && validated.validated === true))
			return !validated.valid ? 'is-invalid' : 'is-valid'
	  },
	  getError(error) {
		 return `errors.${error.toLowerCase().replaceAll(' ', '-').replace('.', '')}`
	  },


	  async onUpdate(e) {


		 const tempPhoto = this.user.photo;

		 if (!this.dirty && this.user.photo !== 'delete') {
			this.user.photo = null;
		 }

		 console.log(this.user.photo)

		 try {
			await this.$store.dispatch("storeSettingUser", this.removeEmpty(this.user))
			await this.$store.dispatch("fetchLoggedUser")
			// if (!this.dirty) {
			//    this.user.photoType = this.photo.split('.').pop();
			this.user.photo = tempPhoto;
			// }

			this.notify(`Αποθηκεύτηκε`);
		 } catch (e) {
			this.$refs.observer.setErrors(e.response.data.errors)

		 }


	  },

	  async initCurrentUser() {
		 this.user.photoType = this.user.photo.split('.').pop();
		 this.photo = this.user.photo;
		 // this.user.photo = await this.getBase64FromUrl(this.user.photo);
	  }

   },

   async beforeMount() {
	  await this.$store.dispatch("fetchSettingUser");

	  this.user = await this.$store.getters.getSettingUser;

	  await this.initCurrentUser();
   },

   async created() {


   },


   computed: {
	  passwordToggleIconOld() {
		 return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
	  },
	  passwordToggleIconNew() {
		 return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
	  },
	  passwordToggleIconRetype() {
		 return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
	  },
   },

}
</script>
