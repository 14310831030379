<template>
   <b-card style="min-height:400px">
	  <b-row>
		 <b-col cols="12">
			<div class="d-flex align-items-center mb-1">
			   <feather-icon icon="ShoppingCartIcon"
							 size="18" />
			   <h4 class="mb-0 ml-75">
				  Υπηρεσίες
			   </h4>
			</div>
			<p class="lead mb-2">{{ $t('settings.services-msg') }}</p>
		 </b-col>
	  </b-row>

	  <div>
		 <div>

			<validation-observer ref="observer"
								 v-slot="{handleSubmit , invalid}"
								 :rules="{ numeric: true , max:10}">
			   <b-form ref="form"
					   class="repeater-form"
					   @submit.prevent="handleSubmit(submit)">
				  <!-- Row Loop -->
				  <b-row v-for="(item, index) in form.services"
						 :id="item.id"
						 :key="item.id"
						 ref="row">

					 <!-- Item Name -->
					 <b-col md="4">
						<validation-provider v-slot="validationContext"
											 :rules="{ required: true }">
						   <b-form-group :class="getValidationClass(validationContext)"
										 label="Υπηρεσία"
										 label-for="item-name">
							  <b-form-input id="item-name"
											v-model="item.title"
											:state="getValidationState(validationContext)"
											placeholder="Eshop, One Page Website"
											type="text" />
						   </b-form-group>
						   <b-form-invalid-feedback> {{ validationContext.errors[0] }}</b-form-invalid-feedback>

						</validation-provider>
					 </b-col>

					 <!-- Τιμή -->
					 <b-col cols="6"
							md="2">
						<b-form-group label="Τιμή"
									  label-for="services-price">
						   <currency-input v-model="item.price"
										   :options="{currencyDisplay: 'hidden', currency: 'EUR',locale:'de-DE',precision:2 }"
										   class="form-control" />
						</b-form-group>
					 </b-col>


					 <b-col class="mb-50"
							col
							md="3">

						<b-button class="mt-0 mt-md-2 btn-icon rounded-circle"
								  variant="outline-danger"
								  @click="removeItem(index)">
						   <feather-icon icon="TrashIcon" />
						</b-button>

						<b-button v-if="index === form.services.length - 1"
								  class="mt-0 ml-2 rounded-circle mt-md-2 btn-icon"
								  variant="success"
								  @click="repeatAgain">
						   <feather-icon
							   icon="PlusIcon" />
						</b-button>
					 </b-col>
					 <b-col cols="12">
						<hr>
					 </b-col>
				  </b-row>

			   </b-form>

			   <b-button :disabled="invalid"
						 variant="primary"
						 @click="submit">
				  {{ $t('generic.save') }}
			   </b-button>


			</validation-observer>


		 </div>

	  </div>

   </b-card>
</template>

<script>
import {BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BRow} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from "@/store";
import ToastMixin from "@/Mixins/ToastMixin";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import CurrencyInput from "@/views/components/Currency";


export default {
   components: {
	  BButton,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BRow,
	  BCol,
	  BCard,
	  BFormInvalidFeedback,
	  vSelect,
	  CurrencyInput,
	  ValidationObserver,
	  ValidationProvider
   },
   mixins: [ToastMixin],


   methods: {
	  repeatAgain(e) {


		 this.form.services.push({});
	  },
	  addServiceIfIsEmpty() {
		 if (!this.form.services.length) {
			this.form.services.push({});
		 }
	  },
	  removeItem(index) {
		 this.form.services.splice(index, 1)
	  },
	  async submit() {


		 await store.dispatch('storeSettings', this.form)


		 await this.notify(`Αποθηκεύτηκε`)
		 this.addServiceIfIsEmpty()
		 this.$refs.observer.reset();

	  },
	  getValidationState({dirty, validated, valid = null}) {
		 this.getValidationClass(validated)
		 return validated ? valid : null;
	  },

	  getValidationClass(validated) {
		 if ((validated.valid !== undefined && validated.validated === true))
			return !validated.valid ? 'is-invalid' : 'is-valid'
	  },
   },

   data() {
	  return {
		 form: {
			services: [],
		 }
	  }
   },


   async created() {

	  await this.$store.dispatch("fetchSettings");
	  this.form.services = await this.$store.getters.getSettings

	  this.addServiceIfIsEmpty()

   }

}
</script>

<style lang="scss"
	   scoped>
.repeater-form {
   overflow: hidden;
   transition: .35s height;
}


.form-group {
   margin-bottom: 0;
}
</style>