<template>
   <b-card style="min-height:400px">
	  <b-row>
		 <b-col cols="12">
			<div v-if="hasError"
				 class="alert-body mb-2 alert alert-dismissible alert-danger p-1">
			   <span> {{ $t('errors.color-error') }} </span>
			</div>
			<div class="d-flex align-items-center mb-1">
			   <feather-icon icon="CalendarIcon"
							 size="18" />
			   <h4 class="mb-0 ml-75">
				  Ημερολόγιο
			   </h4>
			</div>
			<p class="lead mb-2">{{ $t('settings.calendar-msg') }}</p>
		 </b-col>
	  </b-row>

	  <div>
		 <div>


			<validation-observer ref="observer"
								 v-slot="{handleSubmit , invalid}"
								 :rules="{ required: true}">
			   <b-form ref="form"
					   class="repeater-form"
					   @submit.prevent="handleSubmit(submit)">
				  <!-- Row Loop -->
				  <b-row v-for="(item, index) in calendars.calendars"
						 :id="item.id"
						 :key="item.id"
						 ref="row">

					 <!-- Item Name -->
					 <b-col cols="6"
							md="4">
						<validation-provider v-slot="validationContext"
											 :rules="{ required: true }">
						   <b-form-group :class="getValidationClass(validationContext)"
										 label="Ημερολόγιο"
										 label-for="item-name">
							  <b-form-input id="item-name"
											v-model="item.title"
											:state="getValidationState(validationContext)"
											placeholder="Εργασία, Προσωπικό κτλ"
											type="text" />
						   </b-form-group>
						   <b-form-invalid-feedback> {{ validationContext.errors[0] }}</b-form-invalid-feedback>

						</validation-provider>


					 </b-col>

					 <!-- Color -->

					 <b-col
						 class="mt-2"
						 cols="6"
						 md="4"
					 >
						<v-select
							v-model="item.color"
							:clearable="false"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="getColors"
							:reduce="val => val.value"
							:searchable="false"
							class="per-page-selector d-inline-block w-100"
							label="text"
						>
						   <template #option="{ value, label,active,preview }">
							  <div v-if="active"
								   :style="{backgroundColor:value}"
								   class="rounded-circle d-inline-block mr-50"
								   style="height:10px;width:10px" />
							  <span v-if="active"> {{ preview }}</span>
						   </template>
						   <template #selected-option="{ value, label,active,preview }">
							  <div :style="{backgroundColor:value}"
								   class="rounded-circle d-inline-block mr-50"
								   style="height:10px;width:10px" />
							  <span> {{ preview }}</span>
						   </template>
						   <!--						   <template #option="{ value,preview }">-->

						   <!--							  <span class="ml-50"> {{ preview }}</span>-->
						   <!--						   </template>-->

						   <!--						   <template #selected-option="{ value,preview}">-->

						   <!--							  <span class="ml-50">{{ preview }}</span>-->
						   <!--						   </template>-->
						   <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
						</v-select>

					 </b-col>

					 <!-- Remove Button -->
					 <b-col class="mb-50 mt-1 mt-md-0"
							col
							md="3">

						<b-button class="mt-0 mt-md-2 btn-icon rounded-circle"
								  variant="outline-danger"
								  @click="$emit('removeItem',index)">
						   <feather-icon icon="TrashIcon" />
						</b-button>
						<b-button
							v-if="getGeneralCalendar.max > calendars.calendars.length && index === calendars.calendars.length - 1"
							class="mt-0 ml-1 mt-md-2 btn-icon rounded-circle"
							variant="success"
							@click="$emit('repeatAgain')">
						   <feather-icon class=""
										 icon="PlusIcon" />
						</b-button>
					 </b-col>
					 <b-col cols="12">
						<hr>
					 </b-col>
				  </b-row>

			   </b-form>

			   <b-button :disabled="invalid"
						 variant="primary"
						 @click="submit">
				  {{ $t('generic.save') }}
			   </b-button>


			</validation-observer>
		 </div>

	  </div>


   </b-card>
</template>

<script>
import {BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BRow} from 'bootstrap-vue'
import Verte from 'verte';
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ToastMixin from "@/Mixins/ToastMixin";
import store from "@/store";
import {mapGetters} from "vuex";
import {Sketch,} from 'vue-color'
import Button from "@/views/components/button/Button";
import caldoSettings from "@/caldoSettings";
import _ from "lodash";

export default {
   components: {
	  Button,
	  BButton,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BRow,
	  BCol,
	  BCard,
	  Verte,
	  BFormInvalidFeedback,
	  vSelect,
	  ValidationObserver,
	  Sketch,
	  ValidationProvider
   },
   mixins: [ToastMixin],


   props: {
	  calendars: Array | Object
   },

   data() {
	  return {
		 previousSelectedStructures: [],
		 hasError: false,
	  }
   },
   computed: {
	  ...mapGetters(['getGeneralCalendar']),
	  getColors() {
		 let colors = caldoSettings.getCustomLight('all')
		 let selectedColors = _.map(this.calendars.calendars, el => el.color)


		 selectedColors.forEach(el => {
			colors = _.filter(colors, x => {
			   if (x.value === el) {
				  x.active = false

			   }
			   return x
			});
		 })
		 console.log(colors)

		 return caldoSettings.getCustomLight('all');
	  },

   },
   methods: {
	  getSelectedColor(color) {
		 return caldoSettings.getCustomLight('single', color);
	  },
	  async submit() {
		 let isPass = this.calendars.calendars.map(el => el.color)
		 if (!this.containsDuplicates(isPass)) {
			await store.dispatch('storeCalendar', this.calendars)
			await this.notify(`Αποθηκεύτηκε`)
			this.hasError = false
			this.$emit('checkIfEmptyCalendar')
			this.$refs.observer.reset();
		 } else {
			this.hasError = true
		 }

	  },
	  containsDuplicates(array) {
		 if (array.length !== new Set(array).size) {
			return true;
		 }

		 return false;
	  },
	  getValidationState({dirty, validated, valid = null}) {
		 this.getValidationClass(validated)
		 return validated ? valid : null;
	  },

	  getValidationClass(validated) {
		 if ((validated.valid !== undefined && validated.validated === true))
			return !validated.valid ? 'is-invalid' : 'is-valid'
	  },
   },

   async created() {

	  // await this.$store.dispatch("fetchCalendar");
	  await this.$store.dispatch("fetchGeneralSettings");
	  // this.form.calendars = await this.$store.getters.getCalendar


   }
}
</script>


<style lang="scss"
	   scoped>

.color-picker {
   display: flex;
   align-items: center;
   justify-content: flex-start;
}


.verte {
   margin-top: 15px;
}


.repeater-form {
   transition: .35s all;
}


.form-group {
   margin-bottom: 0;
}
</style>