<template>
   <!-- start::card settings επαφών -->
   <b-card style="min-height: 400px">
	  <!-- start::row heading -->
	  <b-row>
		 <b-col cols="12">
			<div class="d-flex align-items-center mb-1">
			   <feather-icon icon="UserIcon"
							 size="18" />
			   <h4 class="mb-0 ml-75">{{ $t('generic.clients') }}</h4>
			</div>
			<p class="lead mb-2">
			   {{ $t('settings.clients-msg') }}
			</p>
		 </b-col>
	  </b-row>
	  <!-- end::row heading -->

	  <!-- start::second row content -->
	  <b-row>
		 <b-col md="6"
				sm="12">

			<validation-observer ref="observer"
								 v-slot="{handleSubmit}">
			   <b-form ref="form"
					   @submit.prevent="handleSubmit(onSubmit)">
				  <b-row>

					 <b-col cols="6">

						<validation-provider v-slot="validationContext"
											 :rules="{ required: true}">
						   <b-form-group label="Κατηγορία"
										 label-for="name">

							  <b-form-input v-model="form.name"
											:state="getValidationState(validationContext)"
											aria-describedby="form-name-live-feedback"
											placeholder="Ιδιώτες κτλ"
											type="text"
											@input="enableButton" />

							  <b-form-invalid-feedback id="form-name-lice-feedback">
								 {{ validationContext.errors[0] }}
							  </b-form-invalid-feedback>

						   </b-form-group>
						</validation-provider>


					 </b-col>

					 <b-col v-if="!form.edit"
							class="mb-50"
							cols="4">
						<b-form-group>
						   <b-button :disabled="addButtonStatus"
									 class="mt-0 mt-md-2 btn-icon"
									 type="submit"
									 variant="primary">
							  <feather-icon icon="PlusIcon" />
							  <span>{{ $t('generic.add') }}</span>
						   </b-button>
						</b-form-group>

					 </b-col>

					 <b-col v-else
							class="mb-50 d-flex"
							cols="12">
						<b-form-group @click="onUpdate">
						   <b-button :disabled="addButtonStatus"
									 class="mt-0 mr-2 mt-md-2 btn-icon"
									 type="submit"
									 variant="success">
							  <feather-icon icon="PlusIcon" />
							  <span>{{ $t('generic.save') }}</span>
						   </b-button>
						</b-form-group>

						<b-form-group>
						   <b-button class="mt-0 mt-md-2 btn-icon"
									 variant="danger"
									 @click="clearEdit">
							  <feather-icon icon="DeleteIcon"
											style="margin-right: 5px" />
							  <span>{{ $t('generic.hidden') }}</span>
						   </b-button>
						</b-form-group>

					 </b-col>

				  </b-row>
			   </b-form>
			</validation-observer>

		 </b-col>
		 <b-col md="6"
				sm="12">
			<b-table :empty-text="$t('errors.no-entries-found')"
					 :fields="tableFields"
					 :items="categories"
					 responsive
					 show-empty
			>
			   <template #cell(actions)="item">
				  <div class="text-nowrap text-right">
					 <b-dropdown no-caret
								 right
								 variant="link">
						<template #button-content>
						   <feather-icon class="align-middle text-body"
										 icon="MoreVerticalIcon"
										 size="16" />
						</template>

						<b-dropdown-item @click="editRow(item)">
						   <feather-icon icon="EditIcon" />
						   <span class="align-middle ml-50">{{ $t('generic.edit') }}</span>
						</b-dropdown-item>

						<b-dropdown-item @click="deleteRow(item)">
						   <feather-icon icon="TrashIcon" />
						   <span class="align-middle ml-50">{{ $t('generic.delete') }}</span>
						</b-dropdown-item>
					 </b-dropdown>

				  </div>
			   </template>
			</b-table>
			<b-pagination
				v-model="filters.currentPage"
				:per-page="filters.selectedPageOptions"
				:total-rows="totalPages"
				aria-controls="contacts-table"
				class="mb-0 mt-1 mt-sm-0"
				first-number
				last-number
				next-class="next-item"
				prev-class="prev-item"
			>
			   <template #prev-text>
				  <feather-icon icon="ChevronLeftIcon"
								size="18" />
			   </template>
			   <template #next-text>
				  <feather-icon icon="ChevronRightIcon"
								size="18" />
			   </template>
			</b-pagination>
		 </b-col>
	  </b-row>
	  <!-- end::row heading -->
   </b-card>
   <!-- end::card settings επαφών -->
</template>

<script>
import {
   BButton,
   BCard,
   BCol,
   BDropdown,
   BDropdownItem,
   BForm,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BPagination,
   BRow,
   BTable
} from "bootstrap-vue";

import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'

import {required} from "vee-validate/dist/rules";
import ToastMixin from "@/Mixins/ToastMixin";
import axiosIns from "@/libs/axios";

// Install VeeValidate rules and localization

extend('required', {
   ...required,
   message: 'Υποχρεωτικό πεδίο'

})


export default {
   components: {
	  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BTable, BDropdown, BDropdownItem, ValidationObserver,
	  ValidationProvider, BFormInvalidFeedback, BPagination,
   },
   mixins: [ToastMixin],
   data() {
	  return {
		 tableFields: [
			{key: 'id', label: 'Id', visible: false},
			{key: 'title', label: 'Ονομασία', sortable: true},
			{key: 'actions', label: ''},
		 ],
		 filters: {
			currentPage: 1,
			selectedPageOptions: 10,
			term: null
		 },
		 totalPages: '',
		 addButtonStatus: 'disabled',
		 form: {
			edit: false,
			id: null,
			name: null
		 },
	  }
   },
   watch: {
	  filters: {
		 deep: true,
		 handler() {
			this.fetchPages()
		 }
	  },
   },
   computed: {
	  categories() {

		 return this.$store.getters.getContactSettingCategoriesR.data;
	  },
   },
   methods: {
	  fetchPages() {

		 this.totalPages = this.$store.getters.getContactSettingCategoriesR.total

	  },
	  getValidationState({dirty, validated, valid = null}) {
		 if (dirty)
			return validated ? valid : null;
	  },
	  enableButton() {
		 if (this.form.name.length >= 2) {
			this.addButtonStatus = false
		 } else {
			this.addButtonStatus = 'disabled'
		 }
	  },
	  editRow({item}) {
		 this.form.name = item.title
		 this.form.id = item.id
		 this.enableButton();
		 this.form.edit = true
		 this.$refs.observer.reset();
		 // this.notify(`Deleted Category  ${item.title}`)
	  },
	  clearEdit() {
		 this.form.name = ""
		 this.form.id = ""
		 this.$refs.observer.reset();
		 this.enableButton();
		 this.form.edit = false
	  },

	  async deleteRow(payload) {
		 const {isConfirmed} = await this.swal()
		 if (isConfirmed) {
			await this.$store.dispatch("deleteContactSettingCategory", payload);
			this.$refs.observer.reset();

			this.notify('Διαγράφηκε', `danger`)
		 }
	  },
	  async onSubmit() {
		 if (this.form.edit === true) {
			this.onUpdate()
		 } else {


			const response = await axiosIns.post(`/contact-category`, {
			   title: this.form.name
			})
			await this.$store.dispatch("storeContactSettingCategory", response);
			this.notify(`Δημιουργήθηκε`)

			this.form.name = '';
		 }
		 this.$refs.observer.reset();
	  },
	  onUpdate(e) {


		 this.$store.dispatch("updateContactSettingCategory", this.form);
		 this.notify(`Αποθηκεύτηκε`)

		 this.clearEdit();
	  }
   },
   created() {


	  this.$store.dispatch("fetchContactSettingCategories", this.filters.currentPage);
	  // this.fetchPages()
   },
};
</script>

<style lang="scss"
	   scoped>
.repeater-form {
   overflow: hidden;
   transition: 0.35s height;
}


.form-group {
   margin-bottom: 0;
}
</style>