<template>
   <!-- start::card settings επαφών -->
   <b-card style="min-height: 400px">
	  <!-- start::row heading -->
	  <b-row>
		 <b-col cols="12">
			<div class="d-flex align-items-center mb-1">
			   <feather-icon icon="UserIcon"
							 size="18" />
			   <h4 class="mb-0 ml-75">{{ $t('settings.partners') }}</h4>
			</div>
			<p class="lead mb-2">
			   {{ $t('settings.partners-msg') }}
			</p>
		 </b-col>
	  </b-row>
	  <!-- end::row heading -->

	  <!-- start::second row content -->
	  <b-row>
		 <b-col md="6"
				sm="12">

			<validation-observer ref="observer"
								 v-slot="{handleSubmit, invalid}">
			   <b-form ref="form"
					   @submit.prevent="handleSubmit(onSubmit)">
				  <b-row>
					 <!--//Start Media-->
					 <b-media class="mb-2 ml-1">
						<template #aside>
						   <b-avatar
							   ref="previewEl"
							   :src="form.photo"
							   rounded
							   size="90px"
							   text="Full Name"
							   variant="light"
						   />
						</template>
						<div class="d-flex flex-wrap mt-4">
						   <label class="d-none mr-1 d-sm-inline cursor-pointer btn btn-outline-secondary"
								  for="file1">
							  <input
								  id="file1"
								  ref="refInputEl1"
								  accept=".jpg,.jpeg,.png"
								  class="d-none"
								  type="file"
								  @change="onPreview($event,form),dirty=true"
							  >
							  {{ $t('contacts.change') }}
							  <feather-icon
								  class="d-inline d-sm-none"
								  icon="EditIcon"
							  />
						   </label>
						   <label
							   class="d-none d-sm-inline cursor-pointer btn btn-outline-secondary"
							   @click="removeImage(form)">
							  {{ $t('contacts.remove') }}
							  <feather-icon
								  class="d-inline d-sm-none"
								  icon="TrashIcon"
							  />
						   </label>
						</div>
					 </b-media>
					 <!--End Media-->

					 <!--//Start Username-->
					 <b-col cols="6">

						<validation-provider v-slot="validationContext"
											 :rules="{ required: true}">
						   <b-form-group :label="$t('contacts.name')"
										 label-for="name">

							  <b-form-input v-model="form.name"
											:state="getValidationState(validationContext)"
											aria-describedby="form-name-live-feedback"
											placeholder="john"
											type="text"
							  />

							  <b-form-invalid-feedback id="form-name-lice-feedback">
								 {{ validationContext.errors[0] }}
							  </b-form-invalid-feedback>

						   </b-form-group>
						</validation-provider>


					 </b-col>
					 <!--End Username-->


					 <!--//Start Lastname-->
					 <b-col cols="6">

						<validation-provider v-slot="validationContext"
											 :rules="{ required: true}">
						   <b-form-group :label="$t('contacts.surname')"
										 label-for="surname">

							  <b-form-input v-model="form.surname"
											:state="getValidationState(validationContext)"
											aria-describedby="form-surname-live-feedback"
											placeholder="Doe"
											type="text"
							  />

							  <b-form-invalid-feedback id="form-surname-lice-feedback">
								 {{ validationContext.errors[0] }}
							  </b-form-invalid-feedback>

						   </b-form-group>
						</validation-provider>


					 </b-col>
					 <!--End Lastname-->

					 <!--//Start Email-->
					 <b-col cols="12 mt-2">

						<validation-provider v-slot="validationContext"
											 :rules="{required:false, email:true}">
						   <b-form-group label="E-mail"
										 label-for="email">

							  <b-form-input v-model="form.email"
											:state="getValidationState(validationContext)"
											aria-describedby="form-email-live-feedback"
											placeholder="mail@site.com"
											type="text"
							  />

							  <b-form-invalid-feedback id="form-email-lice-feedback">
								 {{ validationContext.errors[0] }}
							  </b-form-invalid-feedback>

						   </b-form-group>
						</validation-provider>


					 </b-col>
					 <b-col cols="12 mt-2">

						<validation-provider v-slot="validationContext">
						   <b-form-group label="Τηλέφωνο"
										 label-for="phone">

							  <b-form-input v-model="form.telephone"
											:state="getValidationState(validationContext)"
											aria-describedby="form-email-live-feedback"
											placeholder="6989896545"
											type="text"
							  />

							  <b-form-invalid-feedback id="form-email-lice-feedback">
								 {{ validationContext.errors[0] }}
							  </b-form-invalid-feedback>

						   </b-form-group>
						</validation-provider>


					 </b-col>
					 <!--End Email-->

					 <!--Add Button-->
					 <b-col v-if="!edit"
							class="mb-50"
							cols="4">
						<b-form-group>
						   <b-button :disabled="invalid"
									 class="mt-0 mt-md-2 btn-icon"
									 type="submit"
									 variant="primary">
							  <feather-icon icon="PlusIcon" />
							  <span>{{ $t('generic.add') }}</span>
						   </b-button>
						</b-form-group>

					 </b-col>

					 <!--Edit Button-->
					 <b-col v-else
							class="mb-50 d-flex"
							cols="12">
						<b-form-group @click="onUpdate">
						   <b-button :disabled="invalid"
									 class="mt-0 mr-2 mt-md-2 btn-icon"
									 type="submit"
									 variant="success">
							  <feather-icon icon="SaveIcon"
											style="margin-right: 5px" />
							  <span>{{ $t('generic.save') }}</span>
						   </b-button>
						</b-form-group>

						<b-form-group>
						   <b-button class="mt-0 mt-md-2 btn-icon"
									 variant="danger"
									 @click="clearEdit">
							  <feather-icon icon="DeleteIcon"
											style="margin-right: 5px" />
							  <span>{{ $t('generic.cancel1') }}</span>
						   </b-button>
						</b-form-group>

					 </b-col>

				  </b-row>
			   </b-form>
			</validation-observer>

		 </b-col>

		 <b-col md="6"
				sm="12">
			<b-table
				:busy="isLoading"
				:empty-text="$t('errors.no-entries-found')"
				:fields="tableFields"
				:items="partners"
				responsive
				show-empty>
			   <template #cell(surname)="item">
				  {{ item.item.surname }} {{ item.item.name }}
			   </template>
			   <template #cell(actions)="item">
				  <div class="text-nowrap text-right">
					 <b-dropdown no-caret
								 right
								 variant="link">
						<template #button-content>
						   <feather-icon class="align-middle text-body"
										 icon="MoreVerticalIcon"
										 size="16" />
						</template>

						<b-dropdown-item @click="editRow(item)">
						   <feather-icon icon="EditIcon" />
						   <span class="align-middle ml-50">{{ $t('generic.edit') }}</span>
						</b-dropdown-item>

						<b-dropdown-item @click="deleteRow(item)">
						   <feather-icon icon="TrashIcon" />
						   <span class="align-middle ml-50">{{ $t('generic.delete') }}</span>
						</b-dropdown-item>
					 </b-dropdown>

				  </div>
			   </template>
			</b-table>

			<b-pagination
				v-model="filters.currentPage"
				:per-page="filters.selectedPageOptions"
				:total-rows="totalPages"
				aria-controls="contacts-table"
				class="mb-0 mt-1 mt-sm-0"
				first-number
				last-number
				next-class="next-item"
				prev-class="prev-item"
			>
			   <template #prev-text>
				  <feather-icon icon="ChevronLeftIcon"
								size="18" />
			   </template>
			   <template #next-text>
				  <feather-icon icon="ChevronRightIcon"
								size="18" />
			   </template>
			</b-pagination>
		 </b-col>
	  </b-row>
	  <!-- end::row heading -->
   </b-card>
   <!-- end::card settings επαφών -->
</template>

<script>
import {
   BAvatar,
   BButton,
   BCard,
   BCol,
   BDropdown,
   BDropdownItem,
   BForm,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BMedia,
   BPagination,
   BRow,
   BTable,
} from "bootstrap-vue";

import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'

import {email, required} from "vee-validate/dist/rules";
import ToastMixin from "@/Mixins/ToastMixin";
import ImageMixin from "@/Mixins/ImageMixin";
import FormMixin from "@/Mixins/FormMixin";

// Install VeeValidate rules and localization

extend('required', {
   ...required,
   message: 'Υποχρεωτικό πεδίο'

})
extend('email', {
   ...email,
   message: 'Υποχρεωτικό πεδίο'

})


export default {
   components: {
	  BButton,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BRow,
	  BCol,
	  BCard,
	  BTable,
	  BDropdown,
	  BDropdownItem,
	  ValidationObserver,
	  BMedia,
	  BPagination,
	  BAvatar,
	  ValidationProvider,
	  BFormInvalidFeedback,
   },
   mixins: [ToastMixin, ImageMixin, FormMixin],
   data() {
	  return {
		 tableFields: [
			{key: 'surname', label: 'ΣΥΝΕΡΓΑΤΗΣ', sortable: true},
			{key: 'actions', label: ''},
		 ],
		 filters: {
			currentPage: 1,
			selectedPageOptions: 10,
			term: null
		 },
		 totalPages: this.$store.getters.getTotal,
		 photo: null,
		 dirty: false,
		 isLoading: true,
		 edit: null,
		 form: {
			id: null,
			name: null,
			surname: null,
			email: null,
			photo: null,
			photoType: null,
			telephone: null
		 },
	  }
   },
   computed: {
	  partners() {
		 return this.$store.getters.getPartners.data
	  },
   },
   watch: {
	  filters: {
		 deep: true,
		 handler() {
			this.isLoading = true;
			this.fetchPages()
		 }
	  },
   },
   methods: {
	  async fetchPages() {
		 await this.$store.dispatch('fetchPartners', this.filters.currentPage);
		 this.totalPages = this.$store.getters.getPartners.total
		 this.isLoading = false;
	  },

	  async editRow({item}) {


		 this.form.name = item.name
		 this.form.telephone = item.telephone
		 this.form.surname = item.surname
		 this.form.email = item.email
		 this.form.id = item.id
		 this.form.photo = item.photo
		 this.form.photoType = this.base64Mime(this.form.photo);
		 this.edit = true
		 this.$refs.observer.reset();

		 if (this.form.photoType === 'html') {
			this.form.photoType = null;
			this.form.photo = null;
		 }
	  },
	  base64Mime(encoded) {
		 var result = null;

		 if (typeof encoded !== 'string') {
			return result;
		 }

		 var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

		 if (mime && mime.length) {
			result = mime[1];
		 }

		 return result?.split('/').pop();
	  },
	  async onUpdate(e) {
		 const cloneFood = {...this.form};
		 const temp = {...this.form};


		 if (this.form.photo?.includes("http")) {
			cloneFood.photo = null
		 }

		 await this.$store.dispatch("updatePartner", cloneFood);
		 this.form.photo = temp.photo


		 this.notify(`Αποθηκεύτηκε`)

		 this.clearEdit();
	  },

	  async deleteRow(payload) {
		 const {isConfirmed} = await this.swal()
		 if (isConfirmed) {
			await this.$store.dispatch("deletePartner", payload);
			this.$refs.observer.reset();

			this.notify('Διαγράφηκε', `danger`)
		 }
	  },

	  async onSubmit() {
		 const cloneFood = {...this.form};


		 if (this.form.photo?.includes("http")) {
			cloneFood.photo = null
		 }

		 if (this.edit === true) {
			await this.onUpdate()
		 } else {
			await this.$store.dispatch("storePartner", cloneFood);

			this.notify(`Δημιουργήθηκε`)

			this.clearEdit();

		 }
		 this.$refs.observer.reset();
	  },

	  getValidationState({dirty, validated, valid = null}) {
		 if (dirty)
			return validated ? valid : null;
	  },

	  clearEdit() {
		 this.$refs.observer.reset();
		 this.resetField(this.form)
		 this.edit = false
	  },

   },
   created() {
	  this.fetchPages();
   },
   async mounted() {
	  // this.form.photo = await this.getBase64FromUrl(this.getDefaultImage());
	  // this.form.photoType = 'jpg'
   }
};
</script>

<style lang="scss"
	   scoped>
.repeater-form {
   overflow: hidden;
   transition: 0.35s height;
}


.form-group {
   margin-bottom: 0;
}
</style>