<template>
   <b-card style="min-height:400px">
	  <b-row>
		 <b-col cols="12">
			<div class="d-flex align-items-center mb-1">
			   <feather-icon icon="ShoppingBagIcon"
							 size="18" />
			   <h4 class="mb-0 ml-75">
				  {{ $t('expences.expences') }}
			   </h4>
			</div>
			<p class="lead mb-2">{{ $t('settings.expences-msg') }}</p>
		 </b-col>
	  </b-row>

	  <!-- Να τους δίνουμε 3 default : Χαμηλή, Μεσαία, Υψηλή -->

	  <div>
		 <div>
			<b-form ref="form"
					class="repeater-form"
					@submit.prevent="submit">
			   <!-- Row Loop -->
			   <b-row v-for="(item, index) in form.expenseCategories"
					  :id="item.id"
					  :key="item.id"
					  ref="row">

				  <!-- Item Name -->
				  <b-col md="4">
					 <b-form-group
						 label="Κατηγορία"
						 label-for="item-name">
						<b-form-input id="item-name"
									  v-model="item.title"
									  placeholder="Printworks, Web κτλ"
									  type="text" />
					 </b-form-group>

				  </b-col>

				  <!-- Remove Button -->
				  <b-col class="mb-50 d-flex"
						 col
						 md="3">

					 <b-button class="mt-0 mt-md-2 btn-icon rounded-circle "
							   variant="outline-danger"
							   @click="removeItem(index)">
						<feather-icon icon="TrashIcon" />

					 </b-button>

					 <div v-if="form.expenseCategories.length<5">
						<b-button v-if="index === form.expenseCategories.length - 1"
								  class="mt-0 ml-2 rounded-circle mt-md-2 btn-icon"
								  variant="success"
								  @click="repeatAgain">
						   <feather-icon class=""
										 icon="PlusIcon" />

						</b-button>
					 </div>
				  </b-col>
				  <b-col cols="12">
					 <hr>
				  </b-col>

			   </b-row>

			</b-form>

			<b-button
				variant="primary"
				@click="submit">
			   {{ $t('generic.save') }}
			</b-button>


		 </div>
	  </div>

   </b-card>
</template>

<script>

import {BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BRow} from 'bootstrap-vue'
import Verte from 'verte'
import store from "@/store";
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ToastMixin from "@/Mixins/ToastMixin";

export default {
   components: {
	  BButton,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BRow,
	  BCol,
	  BCard,
	  Verte,
	  BFormInvalidFeedback,
	  vSelect,
	  ValidationObserver,
	  ValidationProvider
   },
   mixins: [ToastMixin],

   data() {
	  return {
		 form: {
			expenseCategories: [],
		 }
	  }
   },
   methods: {
	  repeatAgain(e) {
		 this.form.expenseCategories.push({});
	  },
	  removeItem(index) {
		 this.form.expenseCategories.splice(index, 1)
	  },
	  async submit() {
		 await store.dispatch('storeExpense', this.form)
		 await this.notify(`Αποθηκεύτηκε`)


	  },
   },


   async created() {
	  await this.$store.dispatch("fetchExpenseSetting");
	  this.form.expenseCategories = this.$store.getters.getSettingExpense

	  if (!this.form.expenseCategories.length) {
		 this.form.expenseCategories.push({})
	  }
   },
}
</script>

<style lang="scss"
	   scoped>
@import '~verte/dist/verte.css';


.color-picker {
   display: flex;
   align-items: center;
   justify-content: flex-start;
}


.verte {
   margin-top: 15px;
}


.repeater-form {
   transition: .35s all;
}


.form-group {
   margin-bottom: 0;
}
</style>