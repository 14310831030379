<template>
   <!-- start::tabs -->
   <b-tabs content-class="col-12 col-md-9 mt-1 mt-md-0"
		   nav-class="nav-left"
		   nav-wrapper-class="col-md-3 col-12"
		   pills
		   vertical>

	  <!-- general tab -->
	  <b-tab active>
		 <template #title>
			<feather-icon class="mr-50"
						  icon="UserIcon"
						  size="18" />
			<span class="font-weight-bold">{{ $t('settings.personal-info') }}</span>
		 </template>

		 <setting-user />
	  </b-tab>
	  <!-- general tab -->

	  <!-- company tab -->
	  <b-tab>
		 <template #title>
			<feather-icon class="mr-50"
						  icon="PackageIcon"
						  size="18" />
			<span class="font-weight-bold">{{ $t('contacts.company-info') }}</span>
		 </template>

		 <setting-company />
	  </b-tab>
	  <!-- company tab -->

	  <!-- contacts tab -->
	  <b-tab>
		 <template #title>
			<feather-icon
				class="mr-50"
				icon="UserIcon"
				size="18"
			/>
			<span class="font-weight-bold">{{ $t('generic.clients') }}</span>
		 </template>

		 <setting-contacts />
	  </b-tab>
	  <!-- contacts tab -->

	  <!-- projects tab -->
	  <b-tab>
		 <template #title>
			<feather-icon class="mr-50"
						  icon="ClipboardIcon"
						  size="18" />
			<span class="font-weight-bold">{{ $t('generic.projects') }}</span>
		 </template>

		 <setting-projects />
	  </b-tab>
	  <!-- projects tab -->

	  <!-- services tab -->
	  <b-tab>
		 <template #title>
			<feather-icon class="mr-50"
						  icon="ShoppingCartIcon"
						  size="18" />
			<span class="font-weight-bold">{{ $t('generic.services') }}</span>
		 </template>

		 <setting-services />
	  </b-tab>
	  <!-- services tab -->

	  <!-- calendar tab -->
	  <b-tab @click="reloadColor">
		 <template #title>
			<feather-icon class="mr-50"
						  icon="CalendarIcon"
						  size="18" />
			<span
				class="font-weight-bold calendar-test">{{ $t('generic.calendar') }}</span>
		 </template>

		 <setting-calendar :calendars="form"
						   @checkIfEmptyCalendar="addCalendarIfIsEmpty"
						   @removeItem="removeItem"
						   @repeatAgain="repeatAgain" />
	  </b-tab>
	  <!-- calendar tab -->

	  <!-- todo tab -->
	  <b-tab @click="reloadColorTodo">
		 <template #title>
			<feather-icon class="mr-50"
						  icon="CheckSquareIcon"
						  size="18" />
			<span class="font-weight-bold">{{ $t('generic.todo') }}</span>
		 </template>

		 <setting-todo :tags="formTodo"
					   @checkIfEmptyTodo="addTodoIfIsEmpty"
					   @removeItemTodo="removeItemTodo"
					   @repeatAgainTodo="repeatAgainTodo" />

	  </b-tab>
	  <!-- todo tab -->

	  <!-- expences tab -->
	  <b-tab>
		 <template #title>
			<feather-icon class="mr-50"
						  icon="ShoppingBagIcon"
						  size="18" />
			<span class="font-weight-bold">{{ $t('expences.expences') }}</span>
		 </template>

		 <settings-expences />

	  </b-tab>
	  <!-- expences tab -->

	  <!-- partners tab -->
	  <b-tab>
		 <template #title>
			<feather-icon
				class="mr-50"
				icon="UserIcon"
				size="18"
			/>
			<span class="font-weight-bold">{{ $t('settings.partners') }}</span>
		 </template>

		 <settings-partner />
	  </b-tab>
	  <!-- partners tab -->

	  <!-- mails tab -->
	  <b-tab>
		 <template #title>
			<feather-icon class="mr-50"
						  icon="MailIcon"
						  size="18" />
			<span class="font-weight-bold">{{ $t('generic.emails') }}</span>
		 </template>

		 <setting-mails />
	  </b-tab>
	  <!-- mails tab -->

	  <!-- general tab -->
	  <b-tab>
		 <template #title>
			<feather-icon class="mr-50"
						  icon="SettingsIcon"
						  size="18" />
			<span class="font-weight-bold">{{ $t('settings.general-settings') }}</span>
		 </template>

		 <setting-general />
	  </b-tab>
	  <!-- general tab -->

	  <!-- notifications tab
	  <b-tab>

		 <template #title>
			<feather-icon class="mr-50"
						  icon="BellIcon"
						  size="18" />
			<span class="font-weight-bold">{{ $t('settings.notifications') }}</span>
		 </template>

		 <setting-notification />
	  </b-tab>
	  -->

   </b-tabs>
   <!-- end::tabs -->
</template>

<script>
import {BForm, BTab, BTabs} from "bootstrap-vue";
import SettingUser from "./SettingsUser.vue";
import SettingCompany from "./SettingsCompany.vue";
import SettingContacts from "./SettingsContact";
import SettingProjects from "./SettingsProject.vue";
import SettingServices from "./SettingsServices.vue";
import SettingCalendar from "./SettingsCalendar.vue";
import SettingTodo from "./SettingsTodo.vue";
import SettingMails from "./SettingsMail.vue";
import SettingGeneral from "./SettingsGeneral.vue";
import SettingNotification from "./SettingsNotification.vue";
import SettingsPartner from "@/views/settings/SettingsPartner";
import SettingsExpences from "@/views/settings/SettingsExpences";
import {mapGetters} from "vuex";
import caldoSettings from "@/caldoSettings";

export default {
   components: {
	  BTabs,
	  BTab,
	  BForm,
	  SettingUser,
	  SettingCompany,
	  SettingContacts,
	  SettingProjects,
	  SettingServices,
	  SettingCalendar,
	  SettingTodo,
	  SettingMails,
	  SettingGeneral,
	  SettingNotification,
	  SettingsPartner,
	  SettingsExpences,
   },

   data() {
	  return {
		 form: {
			calendars: {},
		 },
		 formTodo: {
			tags: {},
		 },
		 count: true,
		 countTodo: true
	  }
   },
   computed: {
	  ...mapGetters(['getGeneralCalendar', "getCalendar", "getTags"])
   },
   methods: {

	  repeatAgain() {
		 this.form.calendars.push({});
	  },

	  removeItem(index) {
		 this.form.calendars.splice(index, 1)
	  },
	  reloadColor() {
		 if (this.count) {
			setTimeout(() => {
			   this.form.calendars = this.getCalendar;
			   this.addCalendarIfIsEmpty();
			   this.count = false


			}, 500)

		 }
	  },
	  repeatAgainTodo() {
		 this.formTodo.tags.push({});
	  },

	  removeItemTodo(index) {
		 this.formTodo.tags.splice(index, 1)
	  },

	  reloadColorTodo() {

		 if (this.countTodo) {
			setTimeout(() => {
			   this.formTodo.tags = this.getTags;
			   // this.repeatAgainTodo()
			   this.countTodo = false
			}, 500)
		 }
	  },
	  addCalendarIfIsEmpty() {
		 if (!this.form.calendars.length) {
			this.form.calendars.push({});

		 }
	  },
	  addTodoIfIsEmpty() {
		 if (!this.formTodo.tags.length) {
			this.formTodo.tags.push({});
		 }
	  },

   },
   async created() {
	  caldoSettings.removeBreadcrumb();

	  await this.$store.dispatch("fetchCalendar");
	  await this.$store.dispatch("fetchTodos");
   }
};
</script>
