<template>
   <b-card style="min-height:400px">
	  <b-row>
		 <b-col cols="12">
			<div class="d-flex align-items-center mb-1">
			   <feather-icon icon="CheckSquareIcon"
							 size="18" />
			   <h4 class="mb-0 ml-75">
				  Todo
			   </h4>
			</div>
			<p class="lead mb-2">{{ $t('settings.todo-msg') }}</p>
		 </b-col>
	  </b-row>

	  <!-- Να τους δίνουμε 3 default : Χαμηλή, Μεσαία, Υψηλή -->

	  <div>
		 <div>
			<validation-observer ref="observer"
								 v-slot="{handleSubmit , invalid}"
								 :rules="{ required: true}">
			   <b-form ref="form"
					   class="repeater-form"
					   @submit.prevent="handleSubmit(submit)">

				  <!-- Row Loop -->
				  <b-row
					  v-for="(item, index) in tags.tags"
					  :id="item.id"
					  :key="item.id"
					  ref="row"
				  >

					 <!-- Item Name -->
					 <b-col md="4">
						<validation-provider v-slot="validationContext"
											 :rules="{ required: true }">
						   <b-form-group :class="getValidationClass(validationContext)"
										 label="Tag"
										 label-for="item-name">
							  <b-form-input id="item-name"
											v-model="item.name"
											:state="getValidationState(validationContext)"
											placeholder="Χαμηλή, Υψηλή κτλ"
											type="text" />
						   </b-form-group>
						   <b-form-invalid-feedback> {{ validationContext.errors[0] }}</b-form-invalid-feedback>

						</validation-provider>
					 </b-col>

					 <!-- Color -->
					 <b-col class="color-picker"
							cols="6"
							md="2">
						<verte v-model="item.color"
							   model="hex"
							   picker="square"></verte>
					 </b-col>

					 <!-- Remove Button -->
					 <b-col class="mb-50"
							col
							md="3">

						<b-button class="mt-0 mt-md-2 btn-icon rounded-circle"
								  variant="outline-danger"
								  @click="$emit('removeItemTodo',index)">
						   <feather-icon icon="TrashIcon" />
						</b-button>

						<b-button v-if="getGeneralTodo.tags.max > tags.tags.length && index === tags.tags.length - 1"
								  class="mt-0 ml-2 rounded-circle mt-md-2 btn-icon"
								  variant="success"
								  @click="$emit('repeatAgainTodo')">
						   <feather-icon
							   icon="PlusIcon" />
						</b-button>
					 </b-col>
					 <b-col cols="12">
						<hr>
					 </b-col>
				  </b-row>

			   </b-form>
			   <b-button :disabled="invalid"
						 variant="primary"
						 @click="submit">
				  {{ $t('generic.save') }}
			   </b-button>


			</validation-observer>
		 </div>
	  </div>

   </b-card>
</template>

<script>

import {BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BRow} from 'bootstrap-vue'
import Verte from 'verte'
import store from "@/store";
import {mapGetters} from "vuex";
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ToastMixin from "@/Mixins/ToastMixin";

export default {
   components: {
	  BButton,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BRow,
	  BCol,
	  BCard,
	  Verte,
	  BFormInvalidFeedback,
	  vSelect,
	  ValidationObserver,
	  ValidationProvider
   },
   mixins: [ToastMixin],

   props: {
	  tags: Array | Object
   },
   computed: {
	  ...mapGetters(['getGeneralTodo'])
   },
   methods: {
	  async submit() {
		 await store.dispatch('storeTodos', this.tags)
		 await this.notify(`Αποθηκεύτηκε`)
		 this.$emit('checkIfEmptyTodo')
		 this.$refs.observer.reset();
		 await this.$store.dispatch("fetchTodos");
	  },
	  getValidationState({dirty, validated, valid = null}) {
		 this.getValidationClass(validated)
		 return validated ? valid : null;
	  },

	  getValidationClass(validated) {
		 if ((validated.valid !== undefined && validated.validated === true))
			return !validated.valid ? 'is-invalid' : 'is-valid'
	  },
   },


}
</script>

<style lang="scss"
	   scoped>
@import '~verte/dist/verte.css';


.color-picker {
   display: flex;
   align-items: center;
   justify-content: flex-start;
}


.verte {
   margin-top: 15px;
}


.verte__guide {
   opacity: 0.12 !important;
}


.repeater-form {
   transition: .35s all;
}


.form-group {
   margin-bottom: 0;
}
</style>