<template>
   <b-card style="min-height:400px">
	  <b-row>
		 <b-col cols="12">
			<div class="d-flex align-items-center mb-1">
			   <feather-icon
				   icon="ClipboardIcon"
				   size="18"
			   />
			   <h4 class="mb-0 ml-75">
				  Έργα
			   </h4>
			</div>
			<p class="lead mb-2">{{ $t('settings.projects-msg') }}</p>
		 </b-col>
	  </b-row>

	  <div>
		 <div>

			<validation-observer ref="observer"
								 v-slot="{handleSubmit , invalid}"
								 :rules="{ required: true}">
			   <b-form ref="form"
					   class="repeater-form"
					   @submit.prevent="handleSubmit(submit)">
				  <!-- Row Loop -->
				  <b-row v-for="(item, index) in form.projectCategories"
						 :id="item.id"
						 :key="item.id"
						 ref="row">

					 <!-- Item Name -->
					 <b-col md="4">
						<validation-provider v-slot="validationContext"
											 :rules="{ required: true }">
						   <b-form-group :class="getValidationClass(validationContext)"
										 label="Κατηγορία"
										 label-for="item-name">
							  <b-form-input id="item-name"
											v-model="item.title"
											:state="getValidationState(validationContext)"
											placeholder="Printworks, Web κτλ"
											type="text" />
						   </b-form-group>
						   <b-form-invalid-feedback> {{ validationContext.errors[0] }}</b-form-invalid-feedback>

						</validation-provider>
					 </b-col>

					 <!-- Remove Button -->
					 <b-col class="mb-50"
							col
							md="3">

						<b-button class="mt-0 mt-md-2 btn-icon rounded-circle "
								  variant="outline-danger"
								  @click="removeItem(index)">
						   <feather-icon icon="TrashIcon" />

						</b-button>

						<b-button v-if="index === form.projectCategories.length - 1"
								  class="mt-0 ml-2 rounded-circle mt-md-2 btn-icon"
								  variant="success"
								  @click="repeatAgain">
						   <feather-icon class=""
										 icon="PlusIcon" />

						</b-button>
					 </b-col>
					 <b-col cols="12">
						<hr>
					 </b-col>

				  </b-row>

			   </b-form>

			   <b-button :disabled="invalid"
						 variant="primary"
						 @click="submit">
				  {{ $t('generic.save') }}
			   </b-button>


			</validation-observer>

		 </div>

	  </div>

   </b-card>
</template>

<script>
import {BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BRow} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from "@/store";
import ToastMixin from "@/Mixins/ToastMixin";


import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
   components: {
	  BButton,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BRow,
	  BCol,
	  BCard,
	  BFormInvalidFeedback,
	  vSelect,
	  ValidationObserver,
	  ValidationProvider
   },
   mixins: [ToastMixin],

   methods: {
	  repeatAgain(e) {


		 this.form.projectCategories.push({});
	  },
	  removeItem(index) {
		 this.form.projectCategories.splice(index, 1)
	  },
	  async submit() {

		 await store.dispatch('storeProject', this.form)
		 this.notify(`Αποθηκεύτηκε`)
		 this.addProjectIfIsEmpty()
		 this.$refs.observer.reset();

	  },
	  getValidationState({dirty, validated, valid = null}) {
		 this.getValidationClass(validated)
		 return validated ? valid : null;
	  },

	  getValidationClass(validated) {
		 if ((validated.valid !== undefined && validated.validated === true))
			return !validated.valid ? 'is-invalid' : 'is-valid'
	  },

	  addProjectIfIsEmpty() {
		 if (!this.form.projectCategories.length) {
			this.form.projectCategories.push({});
		 }
	  }
   },

   data() {
	  return {
		 form: {
			projectCategories: [],
		 }
	  }
   },

   async mounted() {

	  // await this.$store.dispatch("fetchProjectSetting");


	  this.form.projectCategories = this.$store.getters.getSettingProject

	  this.addProjectIfIsEmpty()


   }
}

</script>

<style lang="scss"
	   scoped>
.repeater-form {
   overflow: hidden;
   transition: .35s height;
}


.form-group {
   margin-bottom: 0;
}
</style>