<template>
   <b-card>
	  <b-row>
		 <b-col cols="12">
			<div class="d-flex align-items-center mb-2">
			   <feather-icon icon="PackageIcon"
							 size="18" />
			   <h4 class="mb-0 ml-75">Στοιχεία εταιρείας</h4>
			</div>
		 </b-col>
		 <b-col cols="12">
			<!--NOTE: Αν πατήσει το X και το κλείσει δεν εμφανίζεται ξανά -->
			<div v-if="infoBox"
				 aria-atomic="true"
				 aria-live="polite"
				 class="alert alert-dismissible alert-danger"
				 role="alert">
			   <button aria-label="Close"
					   class="close"
					   type="button"
					   @click="onCompanyInfoBox">×
			   </button>
			   <div class="alert-body">
				  <span> Τα στοιχεία της εταιρείας θα εμφανίζονται στα παραστατικά προς τους πελάτες. <br>Εάν συμπληρωθούν, θα αντικαταστήσουν τα προσωπικά σας στοιχεία. </span>
			   </div>
			</div>
		 </b-col>
	  </b-row>

	  <!-- form -->
	  <validation-observer ref="observer"
						   v-slot="{handleSubmit , invalid}">
		 <b-form ref="form"
				 @submit.prevent="handleSubmit(onUpdate)">
			<b-row>
			   <b-col sm="12">
				  <b-media class="mb-2">
					 <template #aside>
						<b-avatar
							ref="previewEl"
							:src="formCompany.photo"
							:text="fullName(	formCompany.name )"
							rounded
							size="90px"
							variant="light"
						/>
					 </template>
					 <div class="d-flex flex-wrap">
						<label class="d-none mr-1 d-sm-inline cursor-pointer btn btn-outline-secondary"
							   for="fileCompany">
						   <input
							   id="fileCompany"
							   ref="refInputEl"
							   accept=".jpg,.jpeg,.png"
							   class="d-none"
							   type="file"
							   @change="onPreview($event,formCompany)"
						   >
						   {{ $t('contacts.change') }}
						   <feather-icon
							   class="d-inline d-sm-none"
							   icon="EditIcon"
						   />
						</label>
						<label class="d-none d-sm-inline cursor-pointer btn btn-outline-secondary"

							   @click="removeImageCompany(formCompany)">
						   {{ $t('contacts.remove') }}
						   <feather-icon
							   class="d-inline d-sm-none"
							   icon="TrashIcon"
						   />
						</label>
					 </div>
					 <small id="passwordHelp"
							ref="photoError"
							class="text-danger d-none">
						Το πεδίο πρέπει να είναι εικόνα (png, jpg, jpeg, gif)
					 </small>
				  </b-media>
			   </b-col>

			   <b-col sm="6">

				  <validation-provider v-slot="validationContext"
									   :rules="{ required: true}">
					 <b-form-group label="Επωνυμία *"
								   label-for="company-name">
						<b-form-input id="company-name"
									  v-model="formCompany.name"
									  :state="getValidationState(validationContext)"
									  name="company-name" />
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>

			   </b-col>

			   <b-col sm="6">
				  <validation-provider v-slot="validationContext"
									   :rules="{ required: true}">
					 <b-form-group label="Διακριτικός τίτλος *"
								   label-for="company-title">
						<b-form-input id="company-title"
									  v-model="formCompany.trademark"
									  :state="getValidationState(validationContext)"
									  name="company-title" />
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="6">
				  <validation-provider v-slot="validationContext"
									   :rules="{ required: true}">
					 <b-form-group label="Δραστηριότητα *"
								   label-for="company-activity">
						<b-form-input id="company-activity"
									  v-model="formCompany.activity"
									  :state="getValidationState(validationContext)"
									  name="company-activity"
						/>
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="6">
				  <validation-provider v-slot="validationContext"
									   :rules="{numeric: true, required: true,max9:9}">
					 <b-form-group label="Α.Φ.Μ. *"
								   label-for="company-vat">
						<b-form-input id="company-vat"
									  v-model="formCompany.vat"
									  :state="getValidationState(validationContext)"
									  name="company-vat" />
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="6">
				  <validation-provider v-slot="validationContext"
									   :rules="{ required: true}">
					 <b-form-group label="Δ.Ο.Υ. *"
								   label-for="company-doy">
						<v-select
							v-model="formCompany.doy"
							:options="getDoy"
							:state="getValidationState(validationContext)"
							autocomplete="true"
							input-id="company-doy"
							label="name"
							placehoder="Επιλέξτε..."
						>
						   <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
						   <template #option="{ code, name}">{{ name }} ({{ code }})</template>
						</v-select>
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="6">
				  <validation-provider v-slot="validationContext"
									   :rules="{ required: true}">
					 <b-form-group label="Διεύθυνση *"
								   label-for="company-address">
						<b-form-input id="company-address"
									  v-model="formCompany.address"
									  :state="getValidationState(validationContext)"
									  name="company-address" />
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="4">
				  <validation-provider v-slot="validationContext"
									   :rules="{ required: true}">
					 <b-form-group label="Νομός *"
								   label-for="counties">
						<v-select
							v-model="formCompany.state"
							:options="getAreas"
							:state="getValidationState(validationContext)"
							input-id="counties"
							label="name"
							placehoder="Επιλέξτε..."
							@input="countySelected"
						>
						   <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
						</v-select>
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="4">
				  <validation-provider v-slot="validationContext"
									   :rules="{ required: true}">
					 <b-form-group label="Περιοχή *"
								   label-for="greekStates">
						<v-select
							v-if="formCompany.state"
							v-model="formCompany.area"
							:options="formCompany.state.areas"
							:state="getValidationState(validationContext)"
							input-id="greekStates"
							label="name"
							placehoder="Επιλέξτε..."
						>
						   <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
						</v-select>
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="4">
				  <validation-provider v-slot="validationContext"
									   :rules="{ required: true,max5:5}">
					 <b-form-group label="Ταχ. κώδικας *"
								   label-for="company-zip">
						<b-form-input id="company-zip"
									  v-model="formCompany.postcode"
									  :state="getValidationState(validationContext)"
									  name="company-zip" />
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="4">
				  <validation-provider v-slot="validationContext"
									   :rules="{ required: false,max10:10}">
					 <b-form-group label="Τηλέφωνο"
								   label-for="company-phone">
						<b-form-input id="company-phone"
									  v-model="formCompany.telephone"
									  :state="getValidationState(validationContext)"
									  name="company-phone" />
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col sm="4">
				  <validation-provider v-slot="validationContext"
									   :rules="{email:false}">
					 <b-form-group label="Επαγγελματικό e-mail"
								   label-for="company-email">
						<b-form-input id="company-email"
									  v-model="formCompany.email"
									  :state="getValidationState(validationContext)"
									  name="company-email" />
						<b-form-invalid-feedback v-if="validationContext.errors[0]">
						   {{ $t(getError(validationContext.errors[0])) }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>
			   </b-col>

			   <b-col cols="12">
				  <b-button
					  :disabled="invalid"
					  class="mt-2 mr-1"
					  type="submit"
					  variant="primary">Αποθήκευση
				  </b-button>
			   </b-col>
			</b-row>
		 </b-form>
	  </validation-observer>
   </b-card>
</template>

<script>
import {
   BAlert,
   BAvatar,
   BButton,
   BCard,
   BCardText,
   BCol,
   BForm,
   BFormCheckbox,
   BFormCheckboxGroup,
   BFormFile,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BFormRadio,
   BFormRadioGroup,
   BImg,
   BInputGroup,
   BInputGroupPrepend,
   BLink,
   BMedia,
   BMediaAside,
   BMediaBody,
   BRow,
   BTab,
   BTabs,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ImageMixin from "@/Mixins/ImageMixin";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {email, image, max, min, numeric, required} from "vee-validate/dist/rules";
import ToastMixin from "@/Mixins/ToastMixin";
import axiosIns from "@/libs/axios";
import {heightTransition} from "@core/mixins/ui/transition";
import FormMixin from "@/Mixins/FormMixin";
import {avatarText} from "@core/utils/filter";

extend('required', {
   ...required,
   message: 'Υποχρεωτικό πεδίο'

})
extend('image', {
   ...image,
   message: 'Υποχρεωτικό πεδίο'

})
extend('numeric', {
   ...numeric,
   message: 'the-must-be-a-number'

})

extend("max9", {
   ...max,
   message: 'max-9-number'
});
extend("max5", {
   ...max,
   message: 'max-5-number'
});
extend("max10", {
   ...max,
   message: 'max-ten-number'
});

extend("min", {
   ...min,
   message: 'min-6-number'
});


extend('email', {
   ...email,
   message: 'Μόρφη Email'
})
export default {
   components: {
	  BButton,
	  BForm,
	  BImg,
	  BFormFile,
	  BFormGroup,
	  BFormInput,
	  BInputGroup,
	  BRow,
	  BCol,
	  BAlert,
	  BCard,
	  BCardText,
	  BMedia,
	  BMediaAside,
	  BMediaBody,
	  BLink,
	  vSelect,
	  ValidationObserver,
	  ValidationProvider,
	  BFormInvalidFeedback,
	  BTabs,
	  BTab,
	  BAvatar,
	  BInputGroupPrepend,
	  BFormRadio,
	  BFormRadioGroup,
	  BFormCheckboxGroup,
	  BFormCheckbox,
   },
   mixins: [ImageMixin, ToastMixin, heightTransition, FormMixin],
   data() {
	  return {
		 formCompany: {},
		 selectedCounty: "",
		 areas: [],
		 selectedArea: "",
		 infoBox: "",
		 photo: null,
		 dirty: false
	  };
   },


   computed: {
	  company() {
		 return this.$store.getters.getUserCompany.company
	  },
	  getDoy() {
		 return this.$store.getters.getDoy;
	  },
	  getAreas() {
		 return this.$store.getters.getAreas
	  },

   },
   methods: {
	  countySelected(value) {
		 this.selectedArea = "";
		 this.areas = value.areas;
	  },

	  getValidationState({dirty, validated, valid = null}) {
		 // this.getValidationClass(validated)
		 return validated ? valid : null;
	  },

	  getValidationClass(validated) {
		 if ((validated.valid !== undefined && validated.validated === true))
			return !validated.valid ? 'is-invalid' : 'is-valid'
	  },
	  fullName(name, surname) {
		 return avatarText(name, surname);
	  },
	  getError(error) {
		 return `errors.${error.toLowerCase().replaceAll(' ', '-').replace('.', '')}`
	  },

	  async onCompanyInfoBox() {
		 const {data} = axiosIns.put('/user-preferences', {
			companyInfoboxOpen: !this.infoBox
		 })
		 this.infoBox = !this.infoBox
	  },
	  hasValidUrlProtocol(url = '') {
		 return ['http://', 'https://', 'ftp://'].some(protocol => url.startsWith(protocol))
	  },

	  async removeImageCompany(form) {
		 form.photo = null
		 form.photoType = 'jpg'
	  },

	  async onUpdate(e) {


		 const formCompany = this.formCompany


		 let data = {
			"name": formCompany.name,
			"trademark": formCompany.trademark,
			"activity": formCompany.activity,
			"vat": formCompany.vat,
			"doy": typeof formCompany.doy === 'object' ? formCompany.doy.name : formCompany.doy,
			"address": formCompany.address,
			"state": typeof formCompany.state === 'object' ? formCompany.state.name : formCompany.state,
			"area": formCompany.area,
			"postcode": formCompany.postcode,
			"telephone": formCompany.telephone,
			"email": formCompany.email,
			"photo": this.formCompany.photo,
			"photoType": this.base64Mime(this.formCompany.photo)
		 }


		 if (this.hasValidUrlProtocol(data.photo)) {
			delete data.photo
			delete data.photoType
		 }


		 try {
			await this.$store.dispatch("updateSettingContact", this.removeEmpty(data))

			// if (!this.dirty) {
			//    this.formCompany.photoType = this.photo.split('.').pop();
			//    this.formCompany.photo = this.photo;
			// }

			this.notify(`Αποθηκεύτηκε`);
		 } catch (e) {
			this.$refs.observer.setErrors(e.response.data.errors)

		 }


	  },


   },
   async created() {
	  await this.$store.dispatch("fetchSettingContact");
	  // await this.$store.dispatch("fetchPreferencesSettings");

	  // this.formCompany.photoType = this.formCompany.photo.split('.').pop();
	  // this.photo = this.formCompany.photo;
	  // this.formCompany.photo = await this.getBase64FromUrl(this.formCompany.photo);

	  this.infoBox = this.$store.getters.getCompanyInfoBoxOpen
	  this.formCompany = this.$store.getters.getSettingCompany
	  this.formCompany.photoError = false;
   },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>