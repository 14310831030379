<template>
   <b-card>
	  <b-row>
		 <h6 class="section-label mx-1 mb-2">
			Activity
		 </h6>
		 <b-col class="mb-2"
				cols="12">
			<b-form-checkbox
				id="accountSwitch1"
				inline
				name="check-button"
				switch
			>
			   <span>Email me when someone comments on my article</span>
			</b-form-checkbox>
		 </b-col>
		 <b-col
			 class="mb-2"
			 cols="12"
		 >
			<b-form-checkbox
				id="accountSwitch2"
				inline
				name="check-button"
				switch
			>
			   <span>Email me when someone answers on my form</span>
			</b-form-checkbox>
		 </b-col>
		 <b-col
			 class="mb-2"
			 cols="12"
		 >
			<b-form-checkbox
				id="accountSwitch3"
				inline
				name="check-button"
				switch
			>
			   <span>Email me hen someone follows me</span>
			</b-form-checkbox>
		 </b-col>

		 <!-- application switch -->
		 <h6 class="section-label mx-1 mt-2">
			Application
		 </h6>
		 <b-col
			 class="mt-1 mb-2"
			 cols="12"
		 >
			<b-form-checkbox
				id="accountSwitch4"
				inline
				name="check-button"
				switch
			>
			   <span>News and announcements</span>
			</b-form-checkbox>
		 </b-col>
		 <b-col
			 class="mb-2"
			 cols="12"
		 >
			<b-form-checkbox
				id="accountSwitch5"
				inline
				name="check-button"
				switch
			>
			   <span>Weekly product updates</span>
			</b-form-checkbox>
		 </b-col>
		 <b-col class="mb-2"
				cols="12">
			<b-form-checkbox id="accountSwitch6"
							 inline
							 name="check-button"
							 switch>
			   <span>Weekly blog digest</span>
			</b-form-checkbox>
		 </b-col>
		 <!--/ application switch -->

		 <!-- buttons -->
		 <b-col cols="12">
			<b-button class="mr-1 mt-1"
					  variant="primary">
			   Save changes
			</b-button>

			<b-button class="mt-1"
					  type="reset"
					  variant="outline-secondary">
			   Cancel
			</b-button>
		 </b-col>
		 <!--/ buttons -->
	  </b-row>
   </b-card>
</template>

<script>
import {BButton, BCard, BCol, BFormCheckbox, BRow,} from 'bootstrap-vue'

export default {
   components: {
	  BButton,
	  BRow,
	  BCol,
	  BCard,
	  BFormCheckbox,
   }
}
</script>
