<template>
   <b-card>
	  <b-row>
		 <h6 class="section-label mx-1 mb-2">
			{{ $t('settings.app-settings') }}
		 </h6>
		 <b-col class="mb-2"
				cols="12">
			<b-form-checkbox
				id="settingsSwitch1"
				v-model="nightModeActive"
				checked="true"
				inline
				name="check-button"
				switch
				@change="handlerChangeSettings('nightModeActive')"
			>
			   <span>{{ $t('settings.auto-dark') }}</span>
			</b-form-checkbox>
		 </b-col>


		 <!-- application switch -->
		 <h6 class="section-label mx-1 mt-2">
			{{ $t('notifications.notifications-2') }}
		 </h6>
		 <b-col
			 class="mb-2"
			 cols="12"
		 >
			<b-form-checkbox
				id="notificationSwitch1"
				v-model="tasksNotifications"
				inline
				name="check-button"
				switch
				@change="handlerChangeSettings('tasksNotifications')"
			>
			   <span>{{ $t('notifications.mail-tasks-notification') }}</span>
			</b-form-checkbox>
		 </b-col>
		 <b-col
			 class="mb-2"
			 cols="12"
		 >
			<b-form-checkbox
				id="notificationSwitch2"
				v-model="mondayNotifications"
				inline
				name="check-button"
				switch
				@change="handlerChangeSettings('mondayNotifications',mondayNotifications)"
			>
			   <span>{{ $t('notifications.all-open-notification') }}</span>
			</b-form-checkbox>
		 </b-col>
		 <!--/ application switch -->


	  </b-row>
   </b-card>
</template>

<script>
import {BButton, BCard, BCol, BFormCheckbox, BRow,} from 'bootstrap-vue'
import axiosIns from "@/libs/axios";
import toastMixin from "@/Mixins/ToastMixin";
import moment from "moment";

import usAppConfig from "@core/app-config/useAppConfig";


export default {
   components: {
	  BButton,
	  BRow,
	  BCol,
	  BCard,
	  BFormCheckbox,
   },
   mixins: [toastMixin],
   data() {
	  return {
		 nightModeActive: false,
		 mondayNotifications: false,
		 tasksNotifications: false
	  }
   },

   created() {
	  setTimeout(() => {
		 this.tasksNotifications = !!this.$store.getters.getTasksNotifications;
		 this.mondayNotifications = !!this.$store.getters.getMondayNotifications
		 this.nightModeActive = !!this.$store.getters.getNightMode

	  }, 800)
   },
   watch: {
	  '$store.getters.getNightMode': function () {
		 this.nightModeActive = !!this.$store.getters.getNightMode
	  }
   },
   methods: {
	  async handlerChangeSettings(value, e) {

		 console.log(e)
		 await axiosIns.put('/user-preferences', {
			[value]: this[value]
		 })

		 if (value === 'nightModeActive') {
			this.nightModeActive = this.nightModeActive ? true : false

			await this.$store.dispatch('changeNightMode', this.nightModeActive)
		 }
		 if (value === 'mondayNotifications') {
			this.mondayNotifications = this.mondayNotifications ? true : false
		 }
		 if (value === 'tasksNotifications') {
			this.tasksNotifications = this.tasksNotifications ? true : false
		 }
		 this.notify("Αποθηκεύτηκε")
	  },
	  changeMode() {
		 const {skin} = usAppConfig

		 var beginningTime = moment('7:00am', 'h:mma');
		 var endTime = moment('9:00pm', 'h:mma');

		 const currentTime = moment();

		 const isBefore = currentTime.isBefore(endTime);
		 const isAfter = currentTime.isAfter(beginningTime);


		 if (isBefore && isAfter) {
			console.log('light')
			skin.value = 'light'
			// localStorage.setItem("vuexy-skin", 'light');
		 } else {
			console.log('dark')
			skin.value = 'dark'
			// localStorage.setItem("vuexy-skin", 'dark');
		 }
	  }
   }
}
</script>
